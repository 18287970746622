//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base/layout';
@import 'layout/layout';

// Pages
//  for page-item active ineer div
.page-item.active .page-link {
  z-index: 3;
  color: #467de1;
  background-color: #d8192300;
  border: 1px solid #467de1;
}

.card-body-custom {
  //   extend .card-body
  //   @extend .card-body;
  //   background: gold !important;
  //   color: #fff;
  height: 480px;
  //   height: 90% !important;
  overflow-y: auto;

  //   max-height: 490px;
  //   overflow-y: auto;
}
.form-switch-custom-red {
  .form-check-input {
    --#{$prefix}form-switch-bg: #{escape-svg($form-switch-bg-image-solid)};
    &:not(:checked) {
      background-color: $form-check-input-bg-solid;
    }
  }
  .form-check-input:checked {
    background-color: #d81923;
    border-color: #d81923;
  }
}

.badge-custom {
  color: #7239ea !important;
  //     bg info-light
  background-color: --var(--bs-info-light);
}

//  phone  number

/* CSS variables. */
:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: currentColor;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInput {
  /* This is done to stretch the contents of this component. */
  display: flex;
  align-items: center;
}

.PhoneInputInput {
  /* The phone number input stretches to fill all empty space */
  flex: 1;
  /* The phone number input should shrink
	   to make room for the extension input */
  min-width: 0;
}

.PhoneInputCountryIcon {
  width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
  /* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  /* Fixes weird vertical space above the flag icon. */
  /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
  display: block;
  /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
  width: 100%;
  height: 100%;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: '';
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

//  add  style  to  input  type tel some style
input[type='tel'] {
  //  extend form this  form-control form-control-lg form-control-solid fw-bolder fs-6 text-dark
  @extend .form-control;
  @extend .form-control-lg;
  @extend .form-control-solid;
  @extend .fw-bolder;
  @extend .fs-6;
  @extend .text-dark;
  //  add  style  to  input  type tel some style
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.symbol .symbol-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: var(--kt-symbol-label-color);
  background-color: var(--kt-symbol-label-bg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.475rem;
}

//   custm style  for  input  type tel

.tab-active {
  background: #e4e4e4;
  border-radius: 8px;
}

// custm-tabs
.custm-tabs {
  .nav-tabs {
    border-bottom: 1px solid #e4e4e4;
    .nav-link {
      border: 0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      color: green;
      font-size: 14px;
      font-weight: 500;
      padding: 0.5rem 1rem;
      margin-bottom: 0;
      &.active {
        background: red;
        border-radius: 8px;
      }
    }
  }
}

#mmm .nav-link.active {
  color: #545454 !important;
  background-color: #e4e4e4 !important;
  height: 52px !important;
  border-radius: 8px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.custom-tabs .nav-link {
  border-bottom: 2px solid transparent;
}

.custom-tabs .nav-link.active {
  border-bottom: 2px solid var(--danger);
  color: var(--danger);
}

.custom-tab {
  padding: 1rem;
  border-bottom: 2px solid transparent;
}

.active-tab {
  border-bottom: 2px solid #b02a37 !important;
}

#CustomTabs .nav-item .nav-link.active,
.nav-item.show .nav-link,
.nav-item .nav-link:hover:not(.disabled) {
  background-color: transparent;
  border: 0;
  color: #b02a37 !important;

  border-bottom: 1px solid #b02a37 !important;
  transition: $transition-link;
}

.disabled {
  opacity: 0.5; /* reduce the opacity of the tab to make it appear disabled */
  cursor: not-allowed; /* change the cursor to show that the tab is disabled */
  pointer-events: none; /* disable pointer events to prevent clicking on the tab */
}
